.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-es-ct {
  background-image: url(/assets/fonts/flag-icon/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/es-ct.svg); }

.flag-icon-eu {
  background-image: url(/assets/fonts/flag-icon/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(/assets/fonts/flag-icon/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(/assets/fonts/flag-icon/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(/assets/fonts/flag-icon/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(/assets/fonts/flag-icon/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gb-wls.svg); }

.flag-icon-un {
  background-image: url(/assets/fonts/flag-icon/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/un.svg); }

.flag-icon-ad {
  background-image: url(/assets/fonts/flag-icon/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ad.svg); }

.flag-icon-ae {
  background-image: url(/assets/fonts/flag-icon/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ae.svg); }

.flag-icon-af {
  background-image: url(/assets/fonts/flag-icon/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/af.svg); }

.flag-icon-ag {
  background-image: url(/assets/fonts/flag-icon/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ag.svg); }

.flag-icon-ai {
  background-image: url(/assets/fonts/flag-icon/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ai.svg); }

.flag-icon-al {
  background-image: url(/assets/fonts/flag-icon/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/al.svg); }

.flag-icon-am {
  background-image: url(/assets/fonts/flag-icon/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/am.svg); }

.flag-icon-ao {
  background-image: url(/assets/fonts/flag-icon/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ao.svg); }

.flag-icon-aq {
  background-image: url(/assets/fonts/flag-icon/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/aq.svg); }

.flag-icon-ar {
  background-image: url(/assets/fonts/flag-icon/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ar.svg); }

.flag-icon-as {
  background-image: url(/assets/fonts/flag-icon/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/as.svg); }

.flag-icon-at {
  background-image: url(/assets/fonts/flag-icon/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/at.svg); }

.flag-icon-au {
  background-image: url(/assets/fonts/flag-icon/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/au.svg); }

.flag-icon-aw {
  background-image: url(/assets/fonts/flag-icon/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/aw.svg); }

.flag-icon-ax {
  background-image: url(/assets/fonts/flag-icon/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ax.svg); }

.flag-icon-az {
  background-image: url(/assets/fonts/flag-icon/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/az.svg); }

.flag-icon-ba {
  background-image: url(/assets/fonts/flag-icon/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ba.svg); }

.flag-icon-bb {
  background-image: url(/assets/fonts/flag-icon/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bb.svg); }

.flag-icon-bd {
  background-image: url(/assets/fonts/flag-icon/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bd.svg); }

.flag-icon-be {
  background-image: url(/assets/fonts/flag-icon/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/be.svg); }

.flag-icon-bf {
  background-image: url(/assets/fonts/flag-icon/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bf.svg); }

.flag-icon-bg {
  background-image: url(/assets/fonts/flag-icon/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bg.svg); }

.flag-icon-bh {
  background-image: url(/assets/fonts/flag-icon/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bh.svg); }

.flag-icon-bi {
  background-image: url(/assets/fonts/flag-icon/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bi.svg); }

.flag-icon-bj {
  background-image: url(/assets/fonts/flag-icon/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bj.svg); }

.flag-icon-bl {
  background-image: url(/assets/fonts/flag-icon/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bl.svg); }

.flag-icon-bm {
  background-image: url(/assets/fonts/flag-icon/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bm.svg); }

.flag-icon-bn {
  background-image: url(/assets/fonts/flag-icon/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bn.svg); }

.flag-icon-bo {
  background-image: url(/assets/fonts/flag-icon/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bo.svg); }

.flag-icon-bq {
  background-image: url(/assets/fonts/flag-icon/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bq.svg); }

.flag-icon-br {
  background-image: url(/assets/fonts/flag-icon/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/br.svg); }

.flag-icon-bs {
  background-image: url(/assets/fonts/flag-icon/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bs.svg); }

.flag-icon-bt {
  background-image: url(/assets/fonts/flag-icon/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bt.svg); }

.flag-icon-bv {
  background-image: url(/assets/fonts/flag-icon/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bv.svg); }

.flag-icon-bw {
  background-image: url(/assets/fonts/flag-icon/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bw.svg); }

.flag-icon-by {
  background-image: url(/assets/fonts/flag-icon/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/by.svg); }

.flag-icon-bz {
  background-image: url(/assets/fonts/flag-icon/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/bz.svg); }

.flag-icon-ca {
  background-image: url(/assets/fonts/flag-icon/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ca.svg); }

.flag-icon-cc {
  background-image: url(/assets/fonts/flag-icon/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cc.svg); }

.flag-icon-cd {
  background-image: url(/assets/fonts/flag-icon/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cd.svg); }

.flag-icon-cf {
  background-image: url(/assets/fonts/flag-icon/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cf.svg); }

.flag-icon-cg {
  background-image: url(/assets/fonts/flag-icon/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cg.svg); }

.flag-icon-ch {
  background-image: url(/assets/fonts/flag-icon/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ch.svg); }

.flag-icon-ci {
  background-image: url(/assets/fonts/flag-icon/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ci.svg); }

.flag-icon-ck {
  background-image: url(/assets/fonts/flag-icon/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ck.svg); }

.flag-icon-cl {
  background-image: url(/assets/fonts/flag-icon/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cl.svg); }

.flag-icon-cm {
  background-image: url(/assets/fonts/flag-icon/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cm.svg); }

.flag-icon-cn {
  background-image: url(/assets/fonts/flag-icon/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cn.svg); }

.flag-icon-co {
  background-image: url(/assets/fonts/flag-icon/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/co.svg); }

.flag-icon-cr {
  background-image: url(/assets/fonts/flag-icon/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cr.svg); }

.flag-icon-cu {
  background-image: url(/assets/fonts/flag-icon/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cu.svg); }

.flag-icon-cv {
  background-image: url(/assets/fonts/flag-icon/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cv.svg); }

.flag-icon-cw {
  background-image: url(/assets/fonts/flag-icon/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cw.svg); }

.flag-icon-cx {
  background-image: url(/assets/fonts/flag-icon/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cx.svg); }

.flag-icon-cy {
  background-image: url(/assets/fonts/flag-icon/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cy.svg); }

.flag-icon-cz {
  background-image: url(/assets/fonts/flag-icon/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/cz.svg); }

.flag-icon-de {
  background-image: url(/assets/fonts/flag-icon/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/de.svg); }

.flag-icon-dj {
  background-image: url(/assets/fonts/flag-icon/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/dj.svg); }

.flag-icon-dk {
  background-image: url(/assets/fonts/flag-icon/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/dk.svg); }

.flag-icon-dm {
  background-image: url(/assets/fonts/flag-icon/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/dm.svg); }

.flag-icon-do {
  background-image: url(/assets/fonts/flag-icon/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/do.svg); }

.flag-icon-dz {
  background-image: url(/assets/fonts/flag-icon/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/dz.svg); }

.flag-icon-ec {
  background-image: url(/assets/fonts/flag-icon/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ec.svg); }

.flag-icon-ee {
  background-image: url(/assets/fonts/flag-icon/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ee.svg); }

.flag-icon-eg {
  background-image: url(/assets/fonts/flag-icon/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/eg.svg); }

.flag-icon-eh {
  background-image: url(/assets/fonts/flag-icon/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/eh.svg); }

.flag-icon-er {
  background-image: url(/assets/fonts/flag-icon/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/er.svg); }

.flag-icon-es {
  background-image: url(/assets/fonts/flag-icon/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/es.svg); }

.flag-icon-et {
  background-image: url(/assets/fonts/flag-icon/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/et.svg); }

.flag-icon-fi {
  background-image: url(/assets/fonts/flag-icon/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fi.svg); }

.flag-icon-fj {
  background-image: url(/assets/fonts/flag-icon/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fj.svg); }

.flag-icon-fk {
  background-image: url(/assets/fonts/flag-icon/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fk.svg); }

.flag-icon-fm {
  background-image: url(/assets/fonts/flag-icon/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fm.svg); }

.flag-icon-fo {
  background-image: url(/assets/fonts/flag-icon/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fo.svg); }

.flag-icon-fr {
  background-image: url(/assets/fonts/flag-icon/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/fr.svg); }

.flag-icon-ga {
  background-image: url(/assets/fonts/flag-icon/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ga.svg); }

.flag-icon-gb {
  background-image: url(/assets/fonts/flag-icon/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gb.svg); }

.flag-icon-gd {
  background-image: url(/assets/fonts/flag-icon/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gd.svg); }

.flag-icon-ge {
  background-image: url(/assets/fonts/flag-icon/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ge.svg); }

.flag-icon-gf {
  background-image: url(/assets/fonts/flag-icon/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gf.svg); }

.flag-icon-gg {
  background-image: url(/assets/fonts/flag-icon/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gg.svg); }

.flag-icon-gh {
  background-image: url(/assets/fonts/flag-icon/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gh.svg); }

.flag-icon-gi {
  background-image: url(/assets/fonts/flag-icon/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gi.svg); }

.flag-icon-gl {
  background-image: url(/assets/fonts/flag-icon/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gl.svg); }

.flag-icon-gm {
  background-image: url(/assets/fonts/flag-icon/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gm.svg); }

.flag-icon-gn {
  background-image: url(/assets/fonts/flag-icon/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gn.svg); }

.flag-icon-gp {
  background-image: url(/assets/fonts/flag-icon/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gp.svg); }

.flag-icon-gq {
  background-image: url(/assets/fonts/flag-icon/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gq.svg); }

.flag-icon-gr {
  background-image: url(/assets/fonts/flag-icon/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gr.svg); }

.flag-icon-gs {
  background-image: url(/assets/fonts/flag-icon/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gs.svg); }

.flag-icon-gt {
  background-image: url(/assets/fonts/flag-icon/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gt.svg); }

.flag-icon-gu {
  background-image: url(/assets/fonts/flag-icon/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gu.svg); }

.flag-icon-gw {
  background-image: url(/assets/fonts/flag-icon/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gw.svg); }

.flag-icon-gy {
  background-image: url(/assets/fonts/flag-icon/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/gy.svg); }

.flag-icon-hk {
  background-image: url(/assets/fonts/flag-icon/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/hk.svg); }

.flag-icon-hm {
  background-image: url(/assets/fonts/flag-icon/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/hm.svg); }

.flag-icon-hn {
  background-image: url(/assets/fonts/flag-icon/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/hn.svg); }

.flag-icon-hr {
  background-image: url(/assets/fonts/flag-icon/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/hr.svg); }

.flag-icon-ht {
  background-image: url(/assets/fonts/flag-icon/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ht.svg); }

.flag-icon-hu {
  background-image: url(/assets/fonts/flag-icon/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/hu.svg); }

.flag-icon-id {
  background-image: url(/assets/fonts/flag-icon/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/id.svg); }

.flag-icon-ie {
  background-image: url(/assets/fonts/flag-icon/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ie.svg); }

.flag-icon-il {
  background-image: url(/assets/fonts/flag-icon/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/il.svg); }

.flag-icon-im {
  background-image: url(/assets/fonts/flag-icon/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/im.svg); }

.flag-icon-in {
  background-image: url(/assets/fonts/flag-icon/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/in.svg); }

.flag-icon-io {
  background-image: url(/assets/fonts/flag-icon/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/io.svg); }

.flag-icon-iq {
  background-image: url(/assets/fonts/flag-icon/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/iq.svg); }

.flag-icon-ir {
  background-image: url(/assets/fonts/flag-icon/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ir.svg); }

.flag-icon-is {
  background-image: url(/assets/fonts/flag-icon/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/is.svg); }

.flag-icon-it {
  background-image: url(/assets/fonts/flag-icon/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/it.svg); }

.flag-icon-je {
  background-image: url(/assets/fonts/flag-icon/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/je.svg); }

.flag-icon-jm {
  background-image: url(/assets/fonts/flag-icon/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/jm.svg); }

.flag-icon-jo {
  background-image: url(/assets/fonts/flag-icon/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/jo.svg); }

.flag-icon-jp {
  background-image: url(/assets/fonts/flag-icon/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/jp.svg); }

.flag-icon-ke {
  background-image: url(/assets/fonts/flag-icon/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ke.svg); }

.flag-icon-kg {
  background-image: url(/assets/fonts/flag-icon/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kg.svg); }

.flag-icon-kh {
  background-image: url(/assets/fonts/flag-icon/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kh.svg); }

.flag-icon-ki {
  background-image: url(/assets/fonts/flag-icon/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ki.svg); }

.flag-icon-km {
  background-image: url(/assets/fonts/flag-icon/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/km.svg); }

.flag-icon-kn {
  background-image: url(/assets/fonts/flag-icon/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kn.svg); }

.flag-icon-kp {
  background-image: url(/assets/fonts/flag-icon/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kp.svg); }

.flag-icon-kr {
  background-image: url(/assets/fonts/flag-icon/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kr.svg); }

.flag-icon-kw {
  background-image: url(/assets/fonts/flag-icon/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kw.svg); }

.flag-icon-ky {
  background-image: url(/assets/fonts/flag-icon/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ky.svg); }

.flag-icon-kz {
  background-image: url(/assets/fonts/flag-icon/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/kz.svg); }

.flag-icon-la {
  background-image: url(/assets/fonts/flag-icon/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/la.svg); }

.flag-icon-lb {
  background-image: url(/assets/fonts/flag-icon/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lb.svg); }

.flag-icon-lc {
  background-image: url(/assets/fonts/flag-icon/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lc.svg); }

.flag-icon-li {
  background-image: url(/assets/fonts/flag-icon/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/li.svg); }

.flag-icon-lk {
  background-image: url(/assets/fonts/flag-icon/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lk.svg); }

.flag-icon-lr {
  background-image: url(/assets/fonts/flag-icon/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lr.svg); }

.flag-icon-ls {
  background-image: url(/assets/fonts/flag-icon/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ls.svg); }

.flag-icon-lt {
  background-image: url(/assets/fonts/flag-icon/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lt.svg); }

.flag-icon-lu {
  background-image: url(/assets/fonts/flag-icon/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lu.svg); }

.flag-icon-lv {
  background-image: url(/assets/fonts/flag-icon/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/lv.svg); }

.flag-icon-ly {
  background-image: url(/assets/fonts/flag-icon/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ly.svg); }

.flag-icon-ma {
  background-image: url(/assets/fonts/flag-icon/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ma.svg); }

.flag-icon-mc {
  background-image: url(/assets/fonts/flag-icon/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mc.svg); }

.flag-icon-md {
  background-image: url(/assets/fonts/flag-icon/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/md.svg); }

.flag-icon-me {
  background-image: url(/assets/fonts/flag-icon/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/me.svg); }

.flag-icon-mf {
  background-image: url(/assets/fonts/flag-icon/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mf.svg); }

.flag-icon-mg {
  background-image: url(/assets/fonts/flag-icon/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mg.svg); }

.flag-icon-mh {
  background-image: url(/assets/fonts/flag-icon/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mh.svg); }

.flag-icon-mk {
  background-image: url(/assets/fonts/flag-icon/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mk.svg); }

.flag-icon-ml {
  background-image: url(/assets/fonts/flag-icon/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ml.svg); }

.flag-icon-mm {
  background-image: url(/assets/fonts/flag-icon/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mm.svg); }

.flag-icon-mn {
  background-image: url(/assets/fonts/flag-icon/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mn.svg); }

.flag-icon-mo {
  background-image: url(/assets/fonts/flag-icon/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mo.svg); }

.flag-icon-mp {
  background-image: url(/assets/fonts/flag-icon/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mp.svg); }

.flag-icon-mq {
  background-image: url(/assets/fonts/flag-icon/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mq.svg); }

.flag-icon-mr {
  background-image: url(/assets/fonts/flag-icon/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mr.svg); }

.flag-icon-ms {
  background-image: url(/assets/fonts/flag-icon/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ms.svg); }

.flag-icon-mt {
  background-image: url(/assets/fonts/flag-icon/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mt.svg); }

.flag-icon-mu {
  background-image: url(/assets/fonts/flag-icon/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mu.svg); }

.flag-icon-mv {
  background-image: url(/assets/fonts/flag-icon/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mv.svg); }

.flag-icon-mw {
  background-image: url(/assets/fonts/flag-icon/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mw.svg); }

.flag-icon-mx {
  background-image: url(/assets/fonts/flag-icon/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mx.svg); }

.flag-icon-my {
  background-image: url(/assets/fonts/flag-icon/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/my.svg); }

.flag-icon-mz {
  background-image: url(/assets/fonts/flag-icon/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/mz.svg); }

.flag-icon-na {
  background-image: url(/assets/fonts/flag-icon/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/na.svg); }

.flag-icon-nc {
  background-image: url(/assets/fonts/flag-icon/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nc.svg); }

.flag-icon-ne {
  background-image: url(/assets/fonts/flag-icon/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ne.svg); }

.flag-icon-nf {
  background-image: url(/assets/fonts/flag-icon/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nf.svg); }

.flag-icon-ng {
  background-image: url(/assets/fonts/flag-icon/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ng.svg); }

.flag-icon-ni {
  background-image: url(/assets/fonts/flag-icon/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ni.svg); }

.flag-icon-nl {
  background-image: url(/assets/fonts/flag-icon/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nl.svg); }

.flag-icon-no {
  background-image: url(/assets/fonts/flag-icon/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/no.svg); }

.flag-icon-np {
  background-image: url(/assets/fonts/flag-icon/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/np.svg); }

.flag-icon-nr {
  background-image: url(/assets/fonts/flag-icon/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nr.svg); }

.flag-icon-nu {
  background-image: url(/assets/fonts/flag-icon/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nu.svg); }

.flag-icon-nz {
  background-image: url(/assets/fonts/flag-icon/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/nz.svg); }

.flag-icon-om {
  background-image: url(/assets/fonts/flag-icon/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/om.svg); }

.flag-icon-pa {
  background-image: url(/assets/fonts/flag-icon/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pa.svg); }

.flag-icon-pe {
  background-image: url(/assets/fonts/flag-icon/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pe.svg); }

.flag-icon-pf {
  background-image: url(/assets/fonts/flag-icon/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pf.svg); }

.flag-icon-pg {
  background-image: url(/assets/fonts/flag-icon/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pg.svg); }

.flag-icon-ph {
  background-image: url(/assets/fonts/flag-icon/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ph.svg); }

.flag-icon-pk {
  background-image: url(/assets/fonts/flag-icon/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pk.svg); }

.flag-icon-pl {
  background-image: url(/assets/fonts/flag-icon/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pl.svg); }

.flag-icon-pm {
  background-image: url(/assets/fonts/flag-icon/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pm.svg); }

.flag-icon-pn {
  background-image: url(/assets/fonts/flag-icon/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pn.svg); }

.flag-icon-pr {
  background-image: url(/assets/fonts/flag-icon/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pr.svg); }

.flag-icon-ps {
  background-image: url(/assets/fonts/flag-icon/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ps.svg); }

.flag-icon-pt {
  background-image: url(/assets/fonts/flag-icon/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pt.svg); }

.flag-icon-pw {
  background-image: url(/assets/fonts/flag-icon/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/pw.svg); }

.flag-icon-py {
  background-image: url(/assets/fonts/flag-icon/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/py.svg); }

.flag-icon-qa {
  background-image: url(/assets/fonts/flag-icon/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/qa.svg); }

.flag-icon-re {
  background-image: url(/assets/fonts/flag-icon/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/re.svg); }

.flag-icon-ro {
  background-image: url(/assets/fonts/flag-icon/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ro.svg); }

.flag-icon-rs {
  background-image: url(/assets/fonts/flag-icon/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/rs.svg); }

.flag-icon-ru {
  background-image: url(/assets/fonts/flag-icon/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ru.svg); }

.flag-icon-rw {
  background-image: url(/assets/fonts/flag-icon/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/rw.svg); }

.flag-icon-sa {
  background-image: url(/assets/fonts/flag-icon/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sa.svg); }

.flag-icon-sb {
  background-image: url(/assets/fonts/flag-icon/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sb.svg); }

.flag-icon-sc {
  background-image: url(/assets/fonts/flag-icon/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sc.svg); }

.flag-icon-sd {
  background-image: url(/assets/fonts/flag-icon/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sd.svg); }

.flag-icon-se {
  background-image: url(/assets/fonts/flag-icon/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/se.svg); }

.flag-icon-sg {
  background-image: url(/assets/fonts/flag-icon/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sg.svg); }

.flag-icon-sh {
  background-image: url(/assets/fonts/flag-icon/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sh.svg); }

.flag-icon-si {
  background-image: url(/assets/fonts/flag-icon/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/si.svg); }

.flag-icon-sj {
  background-image: url(/assets/fonts/flag-icon/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sj.svg); }

.flag-icon-sk {
  background-image: url(/assets/fonts/flag-icon/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sk.svg); }

.flag-icon-sl {
  background-image: url(/assets/fonts/flag-icon/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sl.svg); }

.flag-icon-sm {
  background-image: url(/assets/fonts/flag-icon/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sm.svg); }

.flag-icon-sn {
  background-image: url(/assets/fonts/flag-icon/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sn.svg); }

.flag-icon-so {
  background-image: url(/assets/fonts/flag-icon/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/so.svg); }

.flag-icon-sr {
  background-image: url(/assets/fonts/flag-icon/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sr.svg); }

.flag-icon-ss {
  background-image: url(/assets/fonts/flag-icon/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ss.svg); }

.flag-icon-st {
  background-image: url(/assets/fonts/flag-icon/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/st.svg); }

.flag-icon-sv {
  background-image: url(/assets/fonts/flag-icon/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sv.svg); }

.flag-icon-sx {
  background-image: url(/assets/fonts/flag-icon/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sx.svg); }

.flag-icon-sy {
  background-image: url(/assets/fonts/flag-icon/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sy.svg); }

.flag-icon-sz {
  background-image: url(/assets/fonts/flag-icon/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/sz.svg); }

.flag-icon-tc {
  background-image: url(/assets/fonts/flag-icon/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tc.svg); }

.flag-icon-td {
  background-image: url(/assets/fonts/flag-icon/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/td.svg); }

.flag-icon-tf {
  background-image: url(/assets/fonts/flag-icon/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tf.svg); }

.flag-icon-tg {
  background-image: url(/assets/fonts/flag-icon/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tg.svg); }

.flag-icon-th {
  background-image: url(/assets/fonts/flag-icon/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/th.svg); }

.flag-icon-tj {
  background-image: url(/assets/fonts/flag-icon/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tj.svg); }

.flag-icon-tk {
  background-image: url(/assets/fonts/flag-icon/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tk.svg); }

.flag-icon-tl {
  background-image: url(/assets/fonts/flag-icon/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tl.svg); }

.flag-icon-tm {
  background-image: url(/assets/fonts/flag-icon/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tm.svg); }

.flag-icon-tn {
  background-image: url(/assets/fonts/flag-icon/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tn.svg); }

.flag-icon-to {
  background-image: url(/assets/fonts/flag-icon/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/to.svg); }

.flag-icon-tr {
  background-image: url(/assets/fonts/flag-icon/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tr.svg); }

.flag-icon-tt {
  background-image: url(/assets/fonts/flag-icon/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tt.svg); }

.flag-icon-tv {
  background-image: url(/assets/fonts/flag-icon/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tv.svg); }

.flag-icon-tw {
  background-image: url(/assets/fonts/flag-icon/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tw.svg); }

.flag-icon-tz {
  background-image: url(/assets/fonts/flag-icon/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/tz.svg); }

.flag-icon-ua {
  background-image: url(/assets/fonts/flag-icon/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ua.svg); }

.flag-icon-ug {
  background-image: url(/assets/fonts/flag-icon/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ug.svg); }

.flag-icon-um {
  background-image: url(/assets/fonts/flag-icon/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/um.svg); }

.flag-icon-us {
  background-image: url(/assets/fonts/flag-icon/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/us.svg); }

.flag-icon-uy {
  background-image: url(/assets/fonts/flag-icon/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/uy.svg); }

.flag-icon-uz {
  background-image: url(/assets/fonts/flag-icon/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/uz.svg); }

.flag-icon-va {
  background-image: url(/assets/fonts/flag-icon/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/va.svg); }

.flag-icon-vc {
  background-image: url(/assets/fonts/flag-icon/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/vc.svg); }

.flag-icon-ve {
  background-image: url(/assets/fonts/flag-icon/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ve.svg); }

.flag-icon-vg {
  background-image: url(/assets/fonts/flag-icon/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/vg.svg); }

.flag-icon-vi {
  background-image: url(/assets/fonts/flag-icon/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/vi.svg); }

.flag-icon-vn {
  background-image: url(/assets/fonts/flag-icon/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/vn.svg); }

.flag-icon-vu {
  background-image: url(/assets/fonts/flag-icon/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/vu.svg); }

.flag-icon-wf {
  background-image: url(/assets/fonts/flag-icon/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/wf.svg); }

.flag-icon-ws {
  background-image: url(/assets/fonts/flag-icon/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ws.svg); }

.flag-icon-ye {
  background-image: url(/assets/fonts/flag-icon/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/ye.svg); }

.flag-icon-yt {
  background-image: url(/assets/fonts/flag-icon/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/yt.svg); }

.flag-icon-za {
  background-image: url(/assets/fonts/flag-icon/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/za.svg); }

.flag-icon-zm {
  background-image: url(/assets/fonts/flag-icon/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/zm.svg); }

.flag-icon-zw {
  background-image: url(/assets/fonts/flag-icon/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(/assets/fonts/flag-icon/zw.svg); }
