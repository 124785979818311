/**=====================
    49. Badge CSS start
==========================**/
.badge {
  padding: 0.44em 0.7em;
  margin-right: 3px;
}
/**=====================
    49. Badge CSS Ends
==========================**/
