// Bootstrap Layout scss
@import 'bootstrap/scss/bootstrap';

//style.scss
@import 'scss/style.scss';

// icons css
@import 'css/icofont.css';
@import 'css/themify.css';
@import 'css/flag-icon.css';
@import 'css/whether-icon.css';

// Chartist css
@import 'css/chartist.css';

// Page loader Topbar
@import 'pace/pace-min.css';
@import 'scss/todo/todo';
@import 'css/sticky.css';
@import 'css/scrollable.css';
@import 'css/whether-icon.css';
