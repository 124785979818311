// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$universal-starterkit-primary: mat.define-palette(mat.$indigo-palette);
$universal-starterkit-accent: mat.define-palette(
    mat.$pink-palette,
    A200,
    A100,
    A400
);

// The warn palette is optional (defaults to red).
$universal-starterkit-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$universal-starterkit-theme: mat.define-light-theme(
    (
        color: (
            primary: $universal-starterkit-primary,
            accent: $universal-starterkit-accent,
            warn: $universal-starterkit-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($universal-starterkit-theme);

/* You can add global styles to this file, and also import other style files */
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-material.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// text-editor styles
.ngx-text-editor {
    height: 300px !important;
    display: block !important;
    overflow-y: auto !important;

    ul,
    ul li {
        list-style-type: inherit;
    }
    ul {
        padding-left: 40px;
    }
    ol,
    ol li {
        list-style-type: decimal;
    }
    ol {
        padding-left: 40px;
    }
}

.ag-theme-material .ag-cell {
    padding-right: 0px;
}

.ag-theme-material .ag-header {
    border-bottom: 2px solid black !important;
}

.ag-header-cell-label .ag-header-cell-text {
    justify-content: center !important;
    color: black;
}

.ag-center-cols-viewport {
    overflow-x: hidden !important;
}

.mat-mdc-form-field-underline {
    background-color: transparent !important;
}

.mat-mdc-form-field-ripple {
    background-color: transparent !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-infix {
    padding: 0px;
}

.mat-mdc-form-field-infix {
    border-top: 0px;
}

.btn-link {
    color: #ab8ce4 !important;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
    height: 100% !important;
}

.ag-theme-material .ag-row-focus {
    background-color: #e2e2e2;
}

.ag-theme-material .ag-row :active {
    background-color: white !important;
}

// .ag-theme-material .ag-row {
//   min-height: 8em;
// }

.theme-form .form-control :focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0) !important;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
    box-shadow: none !important;
    border: none !important;

    .ag-input-wrapper {
        border: 1px solid rgb(169, 169, 169);

        .ag-cell-edit-input {
            border-bottom: none !important;
            padding: 10px !important;
            background: white;
            border-width: 0;

            .ag-cell-edit-input {
                border-bottom: none !important;
                padding: 10px !important;
                background: white;
                border-width: 0;
            }
        }
    }
}

.ag-cell-inline-editing .ag-cell-edit-input,
.ag-cell-inline-editing .ag-input-wrapper {
    border-radius: 0px;
    border-bottom: 0px;
}

.capitalize {
    text-transform: capitalize !important;
}

.required-editor {
    width: 100%;
}
.red-borders {
    border: 1px solid red;
}

.ag-theme-material .ag-row-drag {
    font-size: 1.4em !important;
}

.ag-theme-material input[class^='ag-'][type='text'] {
    border-bottom: 0px !important;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-clipper,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
    min-height: 40px;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
    padding-right: 0px;
}

//Common Styles for all forms
.container-fluid {
    outline: none;
}

textarea {
    resize: none !important;
}

.cards1And2Container {
    margin-bottom: 30px !important;
}

.card-container {
    margin-bottom: 0px !important;
    height: 100% !important;
}

.card1 {
    margin-bottom: 0px !important;
    height: 100% !important;
}

.card2 {
    margin-bottom: 0px !important;
    height: 100% !important;
}

.card-style {
    margin-bottom: 0px !important;
    height: 100% !important;
}

.loader-animation {
    animation: spin 1s linear infinite;
}

.ignorePadding {
    padding: 0% !important;
}

.labelStyle {
    margin-bottom: 8px !important;
    font-size: 0.7rem !important;
    font-weight: 600 !important;
}

.hrStyle {
    margin-top: 0% !important;
    margin-bottom: 0.5rem !important;
}

.label-multi-language {
    position: absolute !important;
    margin-top: 0.3rem !important;
}

.image-hint {
    position: absolute;
    color: #005485;
    font-size: 1.6em;
    right: 15px;
    padding: 0;
    z-index: 1;
    top: -2px;
    margin: 0;
}

.tooltip-inner {
    max-width: max-content !important;
}

.ng-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
    padding-bottom: 8px;
    padding-right: 0px;
    line-height: 1.5;
    min-height: unset;
    padding-top: 8px;
}

.ng-select.ng-dropdown-panel .scrollable-content {
    padding-top: 8px;
}

.ng-select.ng-dropdown-panel {
    margin-top: 76px;
}

.ng-select.ng-select-focused
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    color: #495057 !important;
}

.ng-select .ng-select-container {
    color: inherit;
}

.ng-select,
.ng-select div,
.ng-select input,
.ng-select span {
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: inherit;
}

.ng-select.ng-select .ng-dropdown-panel.ng-select-bottom {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
}

.ng-select .ng-select-container:after {
    border-bottom: thin solid #495057 !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: #495057 !important;
}

.ng-select.ng-select-multiple .ng-dropdown-panel.ng-select-bottom {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    align-items: center;
    display: flex;
}

.ng-dropdown-panel.ng-select.ng-select-bottom {
    padding-bottom: 0px !important;
}

.ng-select .ng-clear-wrapper {
    display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex !important;
    align-items: center !important;
}

.image-error {
    color: #dc3545 !important;
    display: inline-block;
    padding-bottom: 1rem;
    text-align: center;
    padding-top: 3px;
    width: 100%;
}

.overlay-form {
    background-color: transparent;
    position: fixed;
    display: none;
    height: 100%;
    width: 100%;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.page-header {
    padding-top: 0px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
    height: fit-content !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
}

.ng-select div,
.ng-select input,
.ng-select span {
    font-size: 0.8rem !important;
    font-weight: 600 !important;
}

.ng-container {
    .ng-select .ng-select-container {
        min-height: 33px !important;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        border-width: 0 0 1px 0 !important;
        border-radius: 0 !important;
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #b29090;
    }
}

.ng-select .ng-select-container {
    min-height: 31px !important;
}

.ng-select.ng-select-single .ng-select-container {
    min-height: 33px !important;
    height: fit-content !important;
}

.ag-theme-material .ag-row :active {
    background-color: transparent !important;
}

.qr-dialog-container .modal-dialog {
    width: 700px !important;
    max-width: 700px !important;
}

ngb-typeahead-window.dropdown-menu {
    width: calc(100% - 30px);
}

.clickable:hover {
    cursor: pointer;
    opacity: 0.7;
}
.mat-mdc-radio-label-content {
    color: black;
}
.full-width {
    width: 100%;
}

.dynamic-ng-select {
    padding-bottom: 1.2em !important;
}

.nav-tabs {
    margin-bottom: 0px !important;
    justify-content: flex-end;

    .nav-link {
        padding: 0.3rem 1rem !important;
        color: #005485 !important;
    }

    .nav-link.active {
        color: #495057 !important;
    }
}

.hidden-row {
    color: #bdbdbd !important ;
    .icon-container {
        opacity: 0.4 !important;
    }
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background: transparent !important;
}

.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
    background-color: transparent !important;
}

mat-button-toggle {
    button {
        line-height: 30px !important;
        padding: 0px !important;

        span {
            line-height: 30px !important;
            padding: 0px !important;
        }
    }
}

.hideDropdownIcon {
    .ng-select .ng-arrow-wrapper {
        display: none !important;
    }
}

.tabs-container {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-body-active {
        position: absolute !important;
    }
}

.spin-loader {
    color: black !important;
    height: 100px !important;
    position: relative !important;

    .loader-small {
        margin-top: 1.9em !important;
        border: 5px solid #f3f3f3;
        margin: auto;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border-top: 5px solid #555;
        border-radius: 50%;
        width: 3.5em;
        height: 3.5em;
    }
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-clipper,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
    min-height: 90px !important;
}

.prevent-editing {
    display: block !important;
    position: absolute !important;
}

.ng-template-multi-language {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top: none !important;
    outline: none !important;
}

.alert-close-btn {
    line-height: 16px !important;
    background: transparent;
    padding: 0px !important;
    margin: 0px !important;
    color: white;
    outline: none;
    border: none;

    span {
        padding: 0px !important;
    }
}

.alert-container {
    .btn-close {
        display: none !important;
    }
}

.input-group-text {
    border-radius: 0.375rem 0 0 0.375rem !important;
    background-color: #e9ecef !important;
    color: #495057 !important;
    height: 100% !important;
}

ngb-modal-backdrop {
    z-index: 1040 !important;
}

@media only screen and (max-width: 575px) {
    .ng-template-multi-language {
        border-top: 1px solid #dee2e6 !important;
        transition: border none;
        -webkit-transition: border none;
    }

    .nav-tabs .nav-item {
        z-index: 2;
    }

    .nav-tabs .nav-item .nav-link {
        border-top: 1px solid transparent !important;
    }

    .nav-tabs .nav-item .nav-link.active {
        border-top: 1px solid #dee2e6 !important;
    }

    .nav-tabs .nav-item .nav-link:hover {
        border-top: 1px solid #dee2e6 !important;
    }
}
