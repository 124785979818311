/**=====================
    05. Header CSS Start
==========================**/
%common {
  color: $black;
  border-left-color: $dark-color;
  transition: all 0.3s ease;
}
@keyframes sidebar-menu-open {
  0% {
    transform: translate(0px, -50%) scale(1, 0);
  }
  100% {
    transform: translate(0px, 0%) scale(1, 1);
  }
}

%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}

/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw;
  .main-header-left {
    width: 280px;
    display: inline-flex;
    height: 100%;
    padding: 12px;
    align-items: center;
    background-color: $white;
    z-index: 9;
    .logo-wrapper {
      display: block;
      position: relative;
      img {
        height: 65px;
        margin-top: 10px;
      }
      a {
        .image-dark {
          display: block;
        }
        .image-light {
          display: none;
        }
      }
    }
    .mobile-sidebar {
      display: inline-block;
      i {
        color: $primary-color;
        font-size: 20px;
        margin-left: 30px;
      }
      .switch {
        margin-bottom: 0;
        width: 30px;
        height: 17px;
        margin-left: 40px;
        margin-top: 10px;
        .switch-state {
          &:before {
            height: 11px;
            width: 11px;
            background-color: $theme-bg-color;
          }
        }
      }
      input {
        &:checked + .switch-state {
          &:before {
            left: -10px;
          }
        }
      }
    }
  }

  .main-header-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    .switch-state {
      background-color: transparent;
      border: 1px solid $primary-color;
    }
    .switch-sm {
      .switch {
        .switch-state {
          &:before {
            bottom: 3px;
            background-color: $primary-color;
          }
        }
      }
    }
    .switch input:checked + .switch-state {
      &:before {
        background-color: $white;
      }
    }
    .nav-left {
      i {
        margin-right: 20px;
      }
      input:focus {
        outline: 0 !important;
      }
    }
    .nav-right {
      text-align: right;
      ul {
        li {
          i {
            font-size: 20px;
          }
        }
      }
      .notification {
        position: absolute;
        top: 21px;
        right: -1px;
        font-size: 9px;
        animation: blink 1.5s infinite;
        @keyframes blink {
          0% {
            opacity: 1;
          }

          70% {
            opacity: 1;
          }

          80% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }
      }
      .icon-user {
        font-size: 16px;
      }
      @keyframes heartbit {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        25% {
          transform: scale(0.1);
          opacity: 0.1;
        }
        50% {
          transform: scale(0.5);
          opacity: 0.3;
        }
        75% {
          transform: scale(0.8);
          opacity: 0.5;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
      }
      > ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > li {
          margin-left: 20px;
          position: relative;
          padding: 30px 0;
          h6 {
            color: $primary-color;
            ul {
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,
              &:after {
                left: inherit;
                right: 10px;
              }
              li {
                display: block;
                a {
                  font-size: 14px;
                  color: $dark-color;
                  i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon {
          font-size: 16px;
        }
      }
      .language-dropdown {
        width: 160px;
        text-align: left;
        li {
          padding-top: 10px;
          a {
            color: $dark-color;
            i {
              margin-right: 10px;
            }
          }
          &:first-child {
            padding-top: 0;
          }
        }
      }
      .profile-dropdown {
        right: -10px;
        left: inherit;
        width: 150px;
        &:before,
        &:after {
          left: inherit;
          right: 10px;
        }
        li {
          display: block;
          text-align: left;
          padding-top: 10px;
          &:first-child {
            padding-top: 0;
          }
          &:hover {
            a {
              color: $primary-color;
              transition: all 0.3s ease;
            }
          }
          a {
            color: $dark-color;
            transition: all 0.3s ease;
          }
          i {
            font-size: 16px;
            margin-right: 10px;
          }
        }
      }
    }
    li {
      display: inline-block;
      position: relative;
    }
  }
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -30px;
  left: initial;
  &:before,
  &:after {
    left: inherit !important;
    right: 35px !important;
  }
  li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid $light-color;
    text-align: left;
    span {
      font-size: 12px;
      color: $dark-color;
      font-family: $font-open-sans;
      i {
        font-size: 11px !important;
        color: $dark-color;
      }
    }
    .notification-icon {
      padding: 15px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 9;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
}
/**======Main Header css Ends ======**/

/*======= Page Header css Start ======= */
.page-wrapper {
  .page-main-header {
    background-color: $theme-bg-color;
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    box-shadow: 0 0 1px 1px $light-color;
  }
  .page-body-wrapper {
    &.sidebar-icon {
      &.sidebar-close {
        .page-sidebar-open {
          left: -150px;
          transition: all 0.5s ease;
        }
      }
      .page-sidebar {
        & ~ .page-body {
          margin-left: 150px;
          width: 100%;
        }
      }
    }
    .page-sidebar {
      width: 255px;
      position: fixed;
      background: $theme-bg-color;
      top: 80px;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
      height: calc(100vh - 80px);
      overflow: auto;
      z-index: 9;
      transform: translate(0px);
      transition: 0.5s;
      .sidebar-user {
        background-color: #f6f7fb;
        padding: 25px 10px;
        box-shadow: 3px 2px 8px -1px rgba(0, 0, 0, 0.13);
        img {
          border: 3px solid $white;
          box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.2);
        }
        h6 {
          color: $dark-color;
          position: relative;
          text-transform: uppercase;
          i {
            margin-right: 10px;
          }
        }
        p {
          color: $white;
        }
        .profile-dropdown {
          top: 44px;
          right: 8px;
          left: inherit;
          width: 130px;
          &:before,
          &:after {
            left: inherit;
            right: 10px;
          }
          li {
            display: block;
            a {
              padding-bottom: 10px;
              display: block;
              color: $dark-color;
            }
            &:last-child {
              a {
                padding-bottom: 0;
              }
            }
          }
        }
      }
      ~ .page-body {
        margin-left: 255px;
        transition: 0.5s;
      }
      .sidebar-menu {
        list-style: none;
        margin: 0;
        padding: 20px;
        .sidebar-title {
          // text-transform: uppercase;
          font-weight: 600;
          color: $primary-color;
          padding-bottom: 10px;
          border-bottom: 1px solid $light-color;
          margin-bottom: 5px;
          letter-spacing: 1px;
          font-size: 14px;
          margin-top: 25px;
        }
        .sidebar-header {
          font-size: 13px;
          letter-spacing: 1px;
          padding-bottom: 7px;
          padding-top: 7px;
          // text-transform: uppercase;
          font-weight: 500;
        }
        > li {
          > a {
            display: block;
            color: $dark-color;
            font-size: 14px;
            transition: all 0.3s ease;
            &.active {
              color: $primary-color;
              transition: all 0.3s ease;
            }
            &:hover {
              color: $primary-color;
              padding-left: 5px;
              transition: all 0.3s ease;
            }
            i {
              margin-right: 7px;
              text-align: right;
              margin-top: 3px;
              font-size: 15px;
              & ~ i {
                margin-right: 0;
              }
            }
          }
          .label {
            margin-top: 3px;
            margin-right: 5px;
          }
          .badge {
            margin-top: 3px;
            margin-right: 5px;
            text-transform: capitalize;
          }
          &:hover > a {
            @extend %common;
          }
          &.active > a {
            @extend %common;
          }
        }
        li {
          &.sidebar-header {
            margin-bottom: 0;
            padding: 15px;
            color: $primary-color;
            font-weight: 600;
            transition: all 0.3s ease;
          }
          &.active {
            > {
              a {
                > .fa-angle-right {
                  transform: rotate(90deg);
                  transition: all 0.3s ease;
                  vertical-align: -7px;
                }
              }
            }
            > .sidebar-submenu {
              display: block;
              -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
              transition: transform 0.3s, opacity 0.3s;
              transform-style: preserve-3d;
              -webkit-transform-style: preserve-3d;
              margin-left: 5px;
            }
          }
          > a {
            > .fa-angle-down {
              width: auto;
              height: auto;
              padding: 0;
              margin-right: 10px;
              margin-top: 3px;
              transition: all 0.3s ease;
            }
          }
        }
        a {
          color: $dark-color;
          text-decoration: none;
        }
        .sidebar-submenu {
          display: none;
          animation: 0.3s alternate forwards sidebar-menu-open;
          list-style: none;
          .sidebar-submenu {
            padding-left: 20px;
            padding-top: 0 !important;
          }
          > li {
            > a {
              padding-bottom: 7px;
              padding-top: 7px;
              display: block;
              font-size: 14px;
              color: $theme-body-font-color;
              transition: all 0.3s ease;
              text-transform: capitalize;
              > {
                .fa {
                  width: 20px;
                }
                i {
                  width: auto;
                }
                .fa-angle-down {
                  width: auto;
                }
              }
              &:hover {
                color: $primary-color;
                transition: all 0.3s ease;
                padding-left: 5px;
              }
              &.active {
                color: $primary-color;
              }
            }
            &.active > a {
              color: $dark-color;
            }
          }
        }
      }
    }
    .page-body {
      min-height: calc(100vh - 80px);
      margin-top: 80px;
      padding: 35px;
      position: relative;
      background-color: #f6f7fb;
    }
    .page-header {
      padding-top: 30px;
      padding-bottom: 30px;
      .row {
        align-items: center;
        h3 {
          font-size: 24px;
          margin-bottom: 0;
          font-weight: 600;
          text-transform: uppercase;
          small {
            display: block;
            font-size: 12px;
            margin-top: 7px;
            letter-spacing: 1px;
            text-transform: capitalize;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
      }
    }
  }
  .sidebar-close {
    .page-sidebar {
      transition: 0.5s;
      transform: translate(-255px);
    }
    .page-body {
      transition: 0.5s;
      margin-left: 0 !important;
    }
  }
}

/*======= Page Header css ends  ======= */

ul {
  .submenu {
    width: 0;
    overflow: hidden;
    list-style-type: none;
    background: $white;
    transition: width 0.5s ease-out;
    position: absolute;
    top: -8px;
    right: -12px;
    input {
      padding: 6px 12px;
      width: 200px;
    }
  }
}
.scorlled {
  .page-sidebar {
    top: 0 !important;
    height: 100vh !important;
  }
}

/** ===== Vertical Menu css Start=====**/

.page-wrapper .vertical-menu-main {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
}
.vertical-menu-mt {
  margin-top: 133px !important;
  .custom-card {
    .card-header {
      img {
        margin-top: -146px !important;
      }
    }
  }
}
.vertical-menu-main {
  #main-nav {
    #main-menu {
      li {
        a {
          i {
            margin-right: 8px;
            font-size: 15px;
          }
        }
      }
    }
  }
  .mega-menu {
    width: 1500px !important;
    max-width: 1500px !important;
    padding: 0;
    left: -182px !important;
  }
}
/** ===== Vertical Menu css Ends=====**/

/* ========sidebar icon vertical menu start=======*/

%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
.compact-switch {
  display: none;
}
.page-wrapper {
  .page-body-wrapper.sidebar-icon {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    .sidebar-header {
      margin-top: 5px;
      text-align: center;
      > span {
        display: block !important;
        font-size: 11px;
        margin-top: 2px;
        color: #777777;
        letter-spacing: 0;
      }
    }
    .page-sidebar {
      opacity: 1;
      z-index: 1;
      position: fixed;
      height: auto;
      display: table;
      width: 145px;
      left: 0;
      transition: all 0.5s ease;
      min-height: calc(100vh - 80px);
      .sidebar-user {
        padding: 10px;
      }
      .sidebar-menu {
        padding: 20px 0;
        > li {
          padding: 0 20px;
          &.active {
            a {
              i,
              span {
                color: $primary-color;
                transition: all 0.3s ease;
              }
            }
          }
          > a {
            &:hover {
              padding-left: 0;
              i,
              span {
                color: $primary-color;
                transition: all 0.3s ease;
              }
            }
          }
        }
        .sidebar-header {
          margin-top: 5px;
          > i {
            font-size: 25px;
            margin: 0;
            color: $dark-gray;
          }
        }
        li {
          &:hover {
            .single-header {
              @extend %sidebar-icon;
            }
            .sidebar-submenu {
              @extend %sidebar-icon;
            }
            ul {
              li {
                .sidebar-submenu {
                  display: none;
                }
              }
            }
          }
          .icon-sidebar {
            font-size: 25px;
            margin: 0;
            color: $dark-gray;
          }
          .sub-header-title {
            display: block;
            font-size: 11px;
            margin-top: 2px;
            color: $theme-body-sub-title-color;
            letter-spacing: 0;
          }
          &.active {
            &:hover {
              > .sidebar-submenu {
                display: block;
              }
            }
            > .sidebar-submenu {
              display: none;
            }
          }
          a:hover + ul {
            @extend %sidebar-icon;
          }
          ul {
            li {
              &:hover {
                .sidebar-submenu {
                  display: block;
                  transform: rotateX(0deg);
                  z-index: 1;
                  background-color: $white;
                  color: $dark-gray;
                  font-size: 14px;
                  opacity: 1;
                  margin-top: -70px;
                  padding: 10px 0;
                  text-align: left;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
                  position: absolute;
                  width: 245px;
                  margin-left: 225px;
                }
              }
            }
          }
        }
      }
    }
    .main-sidebar-menu {
      padding: 0 !important;
      text-align: center;
    }
    .sidebar-title {
      display: none;
    }
    .sidebar-submenu {
      display: none;
    }
    &.page-body-wrapper {
      .page-sidebar {
        .sidebar-menu {
          .sidebar-submenu {
            > li {
              > a {
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }
    .sidebar-header span {
      display: none;
    }
    .sidebar-menu > li > a .fa-angle-right {
      display: none;
    }
  }
}
.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
/* ========sidebar icon vertical menu end=======*/

/* ========sidebar hover vertical menu start=======*/
.page-wrapper {
  .page-body-wrapper.sidebar-hover {
    .page-sidebar {
      transform: translate(-180px);
      transition: 0.5s;
      .sidebar-user,
      .sidebar-header {
        text-align: right !important;
      }
      &:hover {
        transform: translate(0px);
        .sidebar-user {
          text-align: center !important;
        }
        .sidebar-header {
          text-align: left !important;
        }
        .sidebar-menu li .icon-sidebar {
          margin-right: 5px;
        }
      }
      .sidebar-menu li {
        .icon-sidebar {
          margin: 0;
        }
        &.active {
          > .sidebar-submenu {
            display: none;
          }
        }
        &:hover {
          ul {
            li {
              .sidebar-submenu {
                display: none;
              }
            }
          }
        }
      }
    }
    .page-body {
      margin-left: 75px !important;
      #customer-review {
        .owl-stage-outer {
          width: 92%;
          margin: 0 auto;
        }
      }
    }
    .sidebar-title {
      display: none;
    }
    .sidebar-submenu {
      display: none;
    }
    .active {
      display: none;
    }
    &.page-body-wrapper .page-sidebar {
      &:hover {
        .sidebar-menu {
          .sidebar-header {
            text-align: left;
          }
          li {
            &.active {
              > .sidebar-submenu {
                display: inline-block;
              }
            }
          }
          > li {
            > a {
              .fa-angle-right {
                display: inline-block;
              }
            }
          }
        }
        .sidebar-user {
          padding: 25px 10px;
          h6 {
            display: inline-block;
          }
        }
        .sidebar-title {
          display: inline-block;
          width: 100%;
        }
        .active {
          display: inline-block;
          width: 100%;
        }
        .sidebar-header {
          span {
            display: inline-block;
          }
        }
      }
      .sidebar-menu {
        .sidebar-header {
          text-align: center;
        }
      }
      .sidebar-user {
        h6 {
          display: none;
        }
      }
    }
    .sidebar-header span {
      display: none;
    }
    .sidebar-menu {
      > li {
        > a {
          .fa-angle-right {
            display: none;
          }
        }
      }
    }
  }
}
/* ========sidebar hover vertical menu ends=======*/

/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  padding-left: 30px;
  .default-according {
    .card {
      .btn-link {
        font-weight: 500;
        color: gray;
      }
      .card-body {
        font-size: 12px;
      }
    }
  }
  .onhover-show-div {
    width: 1500px;
  }
  .card-body {
    padding: 20px;
  }
  .title {
    color: $theme-body-font-color;
    font-weight: 600;
    border-bottom: 1px solid $light-semi-gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  div {
    > div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .custom-nav-img {
    position: static;
    .desk {
      line-height: 25px;
      font-size: 14px;
      color: $theme-body-sub-title-color;
    }
  }
  .list-unstyled {
    div {
      a {
        padding: 8px 35px 8px 0;
        transition: all 0.3s ease;
        &:hover {
          padding: 8px 35px 8px 10px;
          transition: all 0.3s ease;
        }
      }
    }
  }
  .galleria div {
    margin-bottom: 5px;
    &:hover {
      -webkit-transform: translateY(4px) scale(1.04);
      transform: translateY(4px) scale(1.04);
    }
  }
  .galleria-list .galleria > div {
    width: 25%;
    font-size: 12px;
    float: left;
    color: $black;
    transition: ease-in 0.3s;
    > a {
      display: block;
      margin: 5px 0 10px;
      padding: 0 0 !important;
    }
    img {
      border-radius: 3px;
      max-width: 100%;
    }
    .username {
      font-size: 12px;
      color: $theme-body-font-color;
      margin-bottom: 5px;
      small {
        display: block;
        font-size: 11px;
        color: $theme-body-sub-title-color;
        margin-top: 3px;
      }
    }
  }
  .drilldown {
    overflow: hidden;
    width: 100%;
    padding: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    a {
      width: 100%;
      &:hover {
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
  .drilldown-sub {
    display: none;
  }
  .drilldown-back {
    font-weight: bold;
  }
}
/*=======Mega menu css end=======*/

/*page chat quickview model start*/
.quickview-wrapper {
  position: fixed;
  right: -285px;
  top: 80px;
  width: 285px;
  background: $white;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  height: calc(100% - 80px);
  &.open {
    transform: translate3d(-100%, 0, 0);
  }
  .friend-list-search {
    position: relative;
    background-color: $light-color;
    padding: 20px;
    input {
      color: $dark-gray;
      width: 100%;
      background-color: $white;
      border: 1px solid $light-color;
      padding: 10px 15px;
      border-radius: 4px;
      letter-spacing: 1px;
    }
    .fa {
      position: absolute;
      right: 35px;
      top: 35px;
      font-size: 14px;
      color: $light-gray;
    }
  }
  .recent-activity {
    padding: 20px 40px;
    margin-left: 25px;
    position: relative;
    .user-first-letter {
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        left: 0;
        top: 10px;
        float: left;
        border: 1px dashed $light-color;
      }
      span {
        position: absolute;
        left: -23px;
        width: 45px;
        text-align: center;
        color: $white;
        height: 45px;
        border-radius: 50%;
        z-index: 1;
        font-size: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        top: 10px;
      }
    }
    .activity-content {
      h6 {
        color: $theme-font-color;
      }
      p {
        font-size: 14px;
        color: $semi-dark;
        margin: 0;
      }
    }
  }
  .modal-header {
    .modal-title {
      font-size: 15px;
      color: $theme-font-color;
      letter-spacing: 1px;
      font-weight: 500;
    }
    i {
      font-size: 16px;
      color: $dark-gray;
    }
  }
  .quickview-box {
    .quickview-box-toggle {
      position: absolute;
      width: 54px;
      height: 50px;
      top: 45%;
      left: -50px;
      text-align: center;
      line-height: 54px;
      cursor: pointer;
      padding: 0 !important;
    }
    &:before {
      position: absolute;
      content: "";
      width: 48px;
      height: 50px;
      background-color: $white;
      top: 45%;
      left: -47px;
      box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
      border-left: 1px solid rgba(222, 227, 231, 0.56);
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:after {
      position: absolute;
      content: "";
      background-color: $white;
      width: 5px;
      height: 50px;
      left: 0;
      top: 45%;
    }
  }
  .quickview-recent-activity {
    .quickview-box-toggle {
      position: absolute;
      width: 54px;
      height: 50px;
      top: 52%;
      left: -50px;
      text-align: center;
      line-height: 54px;
      cursor: pointer;
      padding: 0 !important;
    }
    &:before {
      position: absolute;
      content: "";
      width: 48px;
      height: 50px;
      background-color: $white;
      top: 52%;
      left: -47px;
      box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
      border-left: 1px solid rgba(222, 227, 231, 0.56);
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:after {
      position: absolute;
      content: "";
      background-color: $white;
      width: 5px;
      height: 50px;
      left: 0;
      top: 52%;
    }
  }
  .chat-box {
    .friend-list {
      margin-top: 20px;
    }
  }
}
.chat-quickview {
  right: -400px;
  width: 400px;
  .chat-box .chat-left-aside .chat {
    .chat-msg-box {
      height: 650px;
      .message {
        width: 100%;
      }
    }
    .chat-message {
      padding: 30px 0;
      margin: 30px 0;
      border-top: 1px solid $light-color;
    }
  }
}
/*page chat quickview model end*/

/*box layout start*/
.box-layout.page-wrapper {
  .page-body-wrapper.sidebar-hover {
    .page-sidebar {
      width: 75px;
      transform: translate(0px);
      &:hover {
        width: 255px;
      }
    }
  }

  .sidebar-close {
    .page-sidebar {
      &.page-sidebar-open {
        display: none;
      }
    }
  }
  .page-body-wrapper {
    &.sidebar-close {
      .page-sidebar {
        display: none;
      }
    }
    &.sidebar-icon {
      overflow: hidden;
      &.sidebar-close {
        .page-sidebar-open {
          transform: translateX(-150px);
          left: unset;
        }
      }
    }
  }
  .sidebar-icon {
    .page-sidebar {
      position: absolute;
      > div {
        position: fixed;
        background-color: #fff;
        height: 100vh;
        width: 145px;
        -webkit-box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
      }
    }
  }
  .page-main-header {
    .main-header-right {
      margin: 0;
    }
    .vertical-menu-main .mega-menu {
      width: 1050px !important;
      max-width: 1050px !important;
      left: -80px !important;
    }
  }
  .page-body-wrapper {
    width: 1280px;
    box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
    margin: 0 auto;
    .footer-fix {
      width: 1027px;
      margin: 0 auto;
      left: 256px;
      right: 0;
      padding-right: 0;
    }
    .chat-box {
      .chat-history {
        .call-content {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 300px;
          > div {
            height: 450px;
            z-index: 9;
            background-color: rgba(255, 255, 255, 0.75);
            background-blend-mode: overlay;
            width: 100%;
            padding: 30px;
            left: 15px;
          }
          button {
            width: 40%;
            font-size: 14px;
            margin: 0 auto;
          }
        }
        .call-icons {
          margin-top: 20px;
          margin-bottom: 20px;
          ul {
            li {
              border: 1px solid #717171;
              width: 50px;
              height: 50px;
              padding: 8px;
            }
          }
        }
        .receiver-img {
          margin-top: 30px;
          margin-bottom: 30px;
          img {
            width: 38%;
          }
        }
        .total-time {
          h2 {
            font-size: 28px;
            color: #717171;
          }
        }
      }
    }
    canvas {
      &#myLineCharts {
        width: 100%;
      }
    }
    .chat-right-aside {
      flex: 0 0 60%;
      max-width: 60%;
      overflow: hidden;
    }
    .caller-img {
      position: absolute;
      width: 100%;
      max-width: 100%;
      left: 15px;
      img {
        opacity: 0.7;
      }
    }
    .browser-widget {
      img {
        height: 65px;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 39px;
        }
      }
    }
    .custom-card {
      .card-header {
        img {
          margin-top: -73px;
        }
      }
      .card-profile {
        img {
          height: 130px;
          top: -17px;
        }
      }
    }
    .height-scroll {
      max-height: 444px;
    }
    .select2 {
      width: 901.781px;
    }
    &.sidebar-hover {
      .page-body {
        #customer-review {
          .owl-stage-outer {
            width: 85%;
          }
        }
      }
    }
  }
  .page-main-header {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .page-builder {
    .ge-canvas.ge-layout-desktop {
      margin-top: 50px;
    }
    .ge-addRowGroup {
      margin-bottom: 10px;
    }
  }
  .pricing-wrapper-card {
    padding: 50px 20px;
  }
  .card {
    .blog-box {
      &.blog-grid {
        &.set-min-height {
          min-height: 400px;
        }
      }
    }
    .card-body {
      .top-sale-chart {
        canvas {
          &#myLineCharts {
            min-height: 410px;
          }
        }
      }
    }
  }
  .flot-chart-placeholder {
    &#donut-color-chart-morris-daily {
      min-height: 430px;
    }
  }
  .flot-chart-placeholder {
    &#donut-color-chart-morris {
      min-height: 430px;
    }
  }
  .set-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .set-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .set-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .set-col-7 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .set-col-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-header {
          .chat-menu-icons {
            li {
              a {
                i {
                  font-size: 19px;
                }
              }
            }
          }
        }
      }
      flex: 0 0 100%;
      max-width: calc(100% - 15px);
      overflow: hidden;
    }
    .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid #ddd;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 81px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    transition: all linear 0.3s;
  }
  .chat-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .ct-10.total-chart {
    .ct-chart-bar {
      .ct-series {
        .ct-bar {
          stroke-width: 23px !important;
        }
      }
    }
  }
  .email-wrap {
    .email-body {
      .email-compose {
        .cke_contents {
          &.cke_reset {
            max-height: 197px;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .inbox {
          height: 734px;
        }
      }
    }
    .email-content {
      .email-top {
        .user-emailid {
          &:after {
            right: -10px;
            top: -21px;
          }
        }
      }
    }
  }
  .todo {
    .notification-popup {
      right: 320px;
    }
  }
  .touchspin {
    padding: 0 10px;
  }
  .vertical-menu-main {
    width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    .mega-menu {
      width: 1050px !important;
      max-width: 1050px !important;
      left: -320px !important;
    }
  }
  .comingsoon {
    video {
      min-width: 67%;
      width: 67%;
    }
  }
  .authentication-main {
    .auth-innerleft {
      width: 22.333333%;
    }
  }
  .auth-bg-effect {
    .second-effect {
      left: 55%;
    }
  }
  .auth-bg-video {
    video {
      min-width: 67%;
      width: 67%;
    }
  }
}
/*box layout Ends*/

//sidebar widget css start
.sidebar-widget {
  padding-top: 20px;
  border-top: 1px solid $light-color;
  padding-bottom: 20px;
  .sidebar-widget-top {
    margin-bottom: -50px;
    i {
      height: 60px;
      width: 60px;
      background-color: $white;
      display: inline-block;
      font-size: 24px;
      border-radius: 100%;
      line-height: 2.3;
    }
  }
  .sidebar-widget-bottom {
    background-color: $light-color;
    text-align: center;
    p {
      line-height: 2.3;
      padding-top: 26px;
      font-size: 15px;
      margin-bottom: 0;
    }
  }
}
//sidebar widget css End
/**=====================
    05. Header CSS Ends
==========================**/
