/*=============Scrollable css start=============*/
.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  background-color: transparent;
  display: block; }

.scroll-demo {
  padding: 10px;
  position: relative;
  border: 1px solid #eeeeee;
  overflow: auto;
  height: 300px; }
  .scroll-demo .horz-scroll-content {
    width: 1600px; }

.ps-container {
  touch-action: none;
  overflow: hidden !important; }
  .ps-container.ps-in-scrolling {
    pointer-events: none; }
    .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eeeeee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #aaaaaa; }
    .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eeeeee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #aaaaaa; }
  .ps-container > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    border-radius: 4px;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    bottom: 3px;
    height: 8px; }
    .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      background-color: #aaaaaa;
      border-radius: 4px;
      transition: background-color 0.2s linear;
      bottom: 0;
      height: 8px; }
  .ps-container > .ps-scrollbar-y-rail {
    right: 3px;
    width: 8px;
    display: none;
    position: absolute;
    border-radius: 4px;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear; }
    .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      width: 10px;
      position: absolute;
      background-color: #aaaaaa;
      border-radius: 4px;
      transition: background-color 0.2s linear;
      right: 0; }
  .ps-container:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #aaaaaa;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        width: 128px;
        background-color: #999999; }
    .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #aaaaaa;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999999; }
  .ps-container:hover > .ps-scrollbar-x-rail {
    opacity: 0.6; }
    .ps-container:hover > .ps-scrollbar-x-rail:hover {
      background-color: #eeeeee;
      opacity: 0.9; }
      .ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
        background-color: #999999; }
  .ps-container:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
    .ps-container:hover > .ps-scrollbar-y-rail:hover {
      background-color: #eeeeee;
      opacity: 0.9; }
      .ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
        background-color: #aaaaaa; }

.always-visible.ps-container > .ps-scrollbar-x-rail {
  opacity: 0.6; }
.always-visible.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6; }

.large-margin.ps-container > .ps-scrollbar-x-rail {
  margin: 0 25%; }
.large-margin.ps-container > .ps-scrollbar-y-rail {
  margin: 100px 0; }

/*=============Scrollable css end=============*/
